$(document).ready(function(){

    //----------------------------------------------------
    //     Toggle mobile menu
    //----------------------------------------------------
    $("#expand-nav").on("click", function () {
        $(this).toggleClass('nav-mobile-open');
        $("nav > ul").toggleClass('expanded');
    });

    $(".expandable-section button").on("click", function() {
        var parentEl =  $(this).closest('.expandable-section');

        parentEl.toggleClass('expanded');
        $(".expandable-section").not(parentEl).removeClass('expanded');

        // Toggle aria-hidden
        parentEl.children(".section-content").attr("aria-hidden", function(index, attr){
            return attr == "true" ? "false" : "true";
        });

        $(".expandable-section").not(parentEl).children(".section-content").attr("aria-hidden", "true");
    });

    $("#expand-sidebar").on("click", function() {
        $(this).toggleClass('sidebar-open');
        $("aside > ul").toggleClass('expanded');
    });

    $("#calc-submit").on("click", clickHandler.bind(this));

});

function clickHandler( e ) {
    var target = $(e.target);

    $("#result").animate({opacity: 0}, 1000, 'linear', fadeOutCompleteHandler.bind(this, target));

    if ( !$(".submit-results").hasClass('show-result') ) {
        $(".submit-results").addClass("show-result");
        $("#calc-submit").html("Recalculate savings");
    }
}

function fadeOutCompleteHandler ( target ) {
    $("#result").text("£" + calculate( target ) ).animate({opacity: 1}, 1000, 'linear');
}

function calculate (target) {

    var result = 0;

    var amount = $( "#field-one" ).val();
    var fieldTwo = $( "#field-two" ).val();
    var fieldThree = $( "#field-three" ).val();

    if ( target.hasClass( 'savings' ) ){

        var frequency = fieldTwo;
        var years     = fieldThree;

        if( validateInput( amount, "Please enter an amount to save" )  &&
           validateInput(frequency, "Please enter how frequently you can save" ) &&
           validateInput(years, "Please enter how many years you want to save for" ) ){
            result = ( amount * frequency * years ).formatMoney( 2, '.' , ',');
    }else{
        result = 0;
    }

} else if ( target.hasClass( 'interest' ) ) {

    var currentRate = fieldTwo.replace(/%/g,'');
    var newRate     = fieldThree.replace(/%/g,'');

    if( validateInput( amount, "Please enter an amount to save" )  &&
       validateInput(currentRate, "Please enter your current interest rate" ) &&
       validateInput(newRate, "Please enter your new interest rate" ) ) {
        result = amount * ( ( newRate - currentRate ) / 100 ).formatMoney( 2, '.' , ',');
}

} else {
    console.log( "Unexpected calculation required" );
}

return result;
}

function validateInput(val, message) {
    if( isNaN( val ) || val === 0 ) {
        alert( message );
        return false;
    } else {
        return true;
    }
}

Number.prototype.formatMoney = function(c, d, t){
    var n = this,
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d === undefined ? "." : d,
    t = t === undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
    j = ((j = i.length) > 3) ? (j % 3) : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

// Accessibility tab fix for nav bar dropdowns
//----------------------------------------------------
let dropdowns =  document.querySelectorAll('nav ul > li > ul li a');
dropdowns = Array.prototype.slice.call(dropdowns);

const tabHandler = ( e ) => {
    const parent = e.target.closest('ul');
    parent.classList.add('js-access');
}

const tabClear = () => {
    let prev = document.querySelector('.js-access');
    if(prev) prev.classList.remove('js-access');
}

dropdowns.forEach(( item ) => {
    item.addEventListener('blur',tabClear)
});

dropdowns.forEach(( item ) => {
    item.addEventListener('focus',tabHandler)
});

//----------------------------------------------------
//     general mouse/keyboard accessibility fix
//----------------------------------------------------
$(document).on( 'mousedown touchdown', 'a, button, input, select, input[type=radio] + label, input[type=checkbox] + label, label, .button, textarea', function(e) {
    $(this).addClass('no-focus');
}).on('blur touchend', function(e) {
    $(this).removeClass('no-focus');
});
